/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use "ag-grid-community/styles" as ag;

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
.mat-mdc-floating-label {
    display: inline !important;
}

@import "palette";
@import "variables";
@import "bulma/bulma";
@import "custom";
@import "ra-card";
@import "ag-grid-custom";



$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
  ),
  typography: (
    use-system-variables: true,
  ),
    density: (
    scale: -5,
    )
));

@include mat.elevation-classes();
@include mat.app-background();


html {
    overflow-y: auto;
}

html, body {
    @include mat.all-component-themes($light-theme);


    height: 100%;
    font-size: 10.72px;
    font-family: $font-family !important;
    @include mat.elevation-classes();
    @include mat.app-background();
  @include mat.button-overrides((
        filled-container-shape: .5em,
        outlined-container-shape: .5em,
        text-container-shape: .5em,
        protected-container-shape: .5em,

  ));
}

@include ag.grid-styles((
  // ^^^ Include ag.grid-styles which it is no longer nested  in .ag-theme-{name} {}
        theme: material,
        cell-horizontal-padding: 0,
        row-height: 24px,
        header-height: 20px,
        font-size: .8rem,
        font-family: ["Sofia Pro", "Century Gothic", Futura]
));
.station-exceptions-icon {
    font-size: 12px;
    color: white;
    cursor: pointer;
    padding-right: 2em;
    padding-top: 0.4em;
}

.report-save-button {
    width: 100%;
    background-color: $ra-primary-blue;
    color: $ra-text-white;
    padding: 0.5rem;
    border: none;
    border-radius: 0.25rem;
    margin-top: 1.5rem;
    cursor: pointer;
    transition: background-color 0.2s;
}
@include mat.core();
@include mat.color-variants-backwards-compatibility($light-theme);
